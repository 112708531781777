<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com Whatsapp</h4>
      <LineDivider />

      <FormSelect
        label="Provedor"
        placeholder="Serviço de email"
        :value="whatsapp.service"
        @input="updateValue('service', $event)"
        :options="whatsappServiceOptions"
      />

      <FormField
        label="Token"
        placeholder="Token de integração"
        :value="whatsapp.token"
        @input="updateValue('token', $event)"
      />

      <div v-if="whatsapp.service === 'ZENVIA_WHATSAPP'">
        <FormField
          label="Remetente"
          placeholder="Remetente"
          :value="whatsapp.remetenteId"
          @input="updateValue('remetenteId', $event)"
        />

        <FormField
          label="Remetente"
          placeholder="E-mail para notificações sobre processo de aprovação"
          :value="whatsapp.notificationEmail"
          @input="updateValue('notificationEmail', $event)"
        />
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'

export default {
  components: {
    Panel,
    LineDivider,
    FormSelect,
    FormField
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      whatsapp: {
        token: '',
        remetenteId: '',
        service: 'ZENVIA_WHATSAPP',
        notificationEmail: ''
      },
      whatsappServiceOptions: [
        { text: 'Zenvia', value: 'ZENVIA_WHATSAPP' },
        { text: 'Blip', value: 'BLIP_WHATSAPP' }
      ]
    }
  },
  methods: {
    updateValue(field, value) {
      const whatsapp = this.whatsapp

      this.whatsapp[field] = value

      if (whatsapp.service === 'BLIP_WHATSAPP') {
        const blip = {
          service: whatsapp.service,
          token: whatsapp.token
        }
        this.$emit('dataToParent', { evento: 'whatsapp', dados: blip })
      } else {
        this.$emit('dataToParent', { evento: 'whatsapp', dados: this.whatsapp })
      }
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.whatsapp = { ...this.whatsapp, ...newVal.whatsapp }
        }
      }
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}
</style>
