<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com RD Station Marketing</h4>
      <LineDivider />

      <span class="vincular" v-if="rdstation && !rdstation.oauth">
        <a :href="'config' in rdstation && rdstation.config.url_link_account">
          Vincular conta
        </a>
      </span>

      <v-btn
        class="red white--text button"
        @click="$emit('removeOauth')"
        v-else
      >
        Desvincular conta
      </v-btn>

      <a :href="linkAjuda" class="linkajuda" target="_new">
        Ajuda para configurar o RD Station Marketing
      </a>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'

export default {
  components: {
    Panel,
    LineDivider
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['removeOauth'],
  data() {
    return {
      rdstation: null,
      linkAjuda:
        'https://atendimento.lifeapps.com.br/kb/article/380409/integracao-com-rd-station-marketing?ticketId=&q=ajuda%20para%20configurar%20o%20rd%20station%20marketing'
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.rdstation = newVal.rdstation
        }
      }
    }
  }
}
</script>

<style scoped>
a {
  text-align: left;
  text-decoration: none;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}

.button {
  display: block;
  margin-bottom: 20px;
}

.vincular {
  width: 200px;
  height: 40px;
  display: block;
  background-color: #66bb6a;
  font-size: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.vincular a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.vincular:hover {
  opacity: 0.9;
  transition: 0.2s;
}

.linkajuda {
  color: #337ab7 !important;
  font-size: 12px !important;
  display: block;
}
</style>
