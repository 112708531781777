<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com Google Analytics</h4>
      <LineDivider />

      <FormField
        label="API Key"
        placeholder="API Key / Chave da API"
        v-model="configuracao.trackingGoogleAnalytics"
      />

      <div class="message">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        Esta funcionalidade será depreciada, recomendamos que use a
        funcionalidade de tag adicional com o

        <a href="https://www.google.com/intl/pt-BR/tagmanager/" target="_blank">
          Google Tag Manager
        </a>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'

export default {
  components: {
    Panel,
    LineDivider,
    FormField
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      configuracao: {
        trackingGoogleAnalytics: null
      }
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.configuracao = newVal.configuracao
        }
      }
    },
    configuracao: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'configuracao', dados: newVal })
        }
      }
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  cursor: pointer;
  color: #34495e;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}

.message {
  width: 100%;
  padding: 10px;
  background-color: #fb8c00;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  margin-top: 25px;
  font-size: 14px;
}
</style>
