<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração google shopping</h4>
      <LineDivider />

      <FormSelect
        label="Grupo de preço"
        placeholder="Selecione o grupo de preço padrão"
        v-model="googleShopping.grupoPreco"
        :options="googleShoppingOptions"
      />

      <FormField
        label="Marca padrão"
        placeholder="Marca padrão"
        v-model="googleShopping.marcaPadrao"
        help="Quando o produto não possuir a caracteristica gênero (google-gender) este valor será usado"
      />

      <div class="feeds">
        <label>Feed de produtos(Xml)</label>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3" style="padding-right: 12px;"
              >far fa-question-circle</v-icon
            >
          </template>
          Use o endereço abaixo para baixar o arquivo com os produtos.
        </v-tooltip>

        <a :href="urlGoogleShopping" target="_new">
          {{ urlGoogleShopping }}
        </a>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import { mapGetters } from 'vuex'

export default {
  components: {
    Panel,
    LineDivider,
    FormSelect,
    FormField
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      googleShopping: {
        grupoPreco: '',
        marcaPadrao: ''
      },
      googleShoppingOptions: [],
      urlGoogleShopping: null,
      seo: {
        slug: ''
      },
      dominio: ''
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId'])
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          const groupsOptions = []

          newVal.priceGroups.forEach(({ codigo_grupo_preco }) => {
            groupsOptions.push({
              text: codigo_grupo_preco,
              value: codigo_grupo_preco
            })
          })

          this.urlGoogleShopping = newVal.urlGoogleShopping
          this.googleShopping = newVal.googleShopping
          this.googleShoppingOptions = groupsOptions
          this.dominio = newVal.dominio
          this.seo = newVal.seo

          this.urlGoogleShopping =
            this.dominio &&
            `http://${this.dominio}/shopping/${this.seo.slug ||
              this.getFornecedorId}/google.xml`
        }
      }
    },
    googleShopping: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', {
            evento: 'googleShopping',
            dados: newVal
          })
        }
      }
    }
  }
}
</script>

<style scoped>
a {
  color: #337ab7;
  font-size: 16px;
  display: block;
  text-align: left;
  text-decoration: none;
  padding-top: 3px;
}

label {
  font-weight: 700;
  color: #6a6c6f;
  padding-top: 3px;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}

.feeds {
  display: flex;
}
</style>
