<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com BLIP</h4>
      <LineDivider />

      <FormField
        label="App key"
        placeholder="Chave para a integração com o chat bot"
        v-model="chatBot.appKey"
      />

      <div class="plataformas">
        <label>Utilizar nas plataformas:</label>

        <div>
          <FormCheckbox label="Aplicativo" v-model="chatBot.app" />

          <FormCheckbox label="Site" v-model="chatBot.web" />
        </div>
      </div>

      <FormField
        label="Texto do botão"
        placeholder="Texto do botão no aplicativo"
        v-model="chatBot.buttonTitle"
      />

      <label style="padding-bottom: 18px;">Cor do botão</label>
      <div class="cor-do-botao">
        <input
          class="input-cor"
          v-model="chatBot.buttonColor"
          type="text"
          @focus="showColorPicker"
          title="Cor do botão do chat bot no commerce"
        />

        <div class="color" @click="togglePicker">
          <span :style="{ 'background-color': chatBot.buttonColor }"></span>
        </div>
      </div>

      <div
        v-if="showPicker"
        ref="picker"
        @click.stop
        style="margin-bottom: 20px; margin-top: -20px;"
      >
        <v-color-picker v-model="chatBot.buttonColor" hide-inputs show-swatches>
        </v-color-picker>
      </div>

      <a :href="linkAjuda" target="_new">
        Ajuda para configurar o BLIP
      </a>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormCheckbox from '@/components/form/FormCheckbox'

export default {
  components: {
    Panel,
    LineDivider,
    FormField,
    FormCheckbox
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      chatBot: {
        buttonTitle: '',
        buttonColor: 'red',
        appKey: '',
        app: true,
        web: true
      },
      showPicker: false,
      linkAjuda:
        'https://atendimento.lifeapps.com.br/kb/article/380419/como-integrar-o-chatbot-blip-com-o-seu-e-commerce?ticketId=&q=como%20integrar%20o%20chatbot%20blip%20com%20o%20seu%20ecommerce'
    }
  },
  methods: {
    showColorPicker() {
      this.colorPickerActive = true
    },
    togglePicker() {
      this.showPicker = !this.showPicker
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.chatBot = { ...newVal.chatBot }
        }
      }
    },
    chatBot: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'chatBot', dados: newVal })
        }
      }
    }
  }
}
</script>

<style scoped>
label {
  display: block;
  text-align: left;
  font-weight: 700;
  color: #6a6c6f;
}

input:focus {
  outline: none;
}

a {
  color: #337ab7 !important;
  font-size: 12px !important;
  display: block;
  text-align: left;
  text-decoration: none;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}

.plataformas {
  display: flex;
  align-items: center;
}

.plataformas div {
  margin-left: 20px;
  width: 50%;
  display: flex;
}

.cor-do-botao {
  display: flex;
  margin-bottom: 20px;
}

.input-cor {
  background: #fafbfc;
  border: 1px solid #e4e5e7;
  border-radius: 4px 0 0 4px;
  padding-left: 12px;
  text-align: center;
  width: 261px;
}

.color {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafbfc;
  border: 1px solid #e4e5e7;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.color span {
  width: 12px;
  height: 12px;
  background-color: transparent;
  display: inline-block;
}
</style>
