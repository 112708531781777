<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com serviço de e-mail</h4>
      <LineDivider />

      <FormSelect
        label="Serviço"
        placeholder="Serviço de email"
        v-model="mailService"
        :options="mailServiceOptions"
      />

      <div v-if="mailService === 'ELASTIC_MAIL'">
        <FormField
          label="API Key"
          placeholder="API Key / Chave da API"
          v-model="elasticmail.apiKey"
        />

        <FormField
          label="Public Account ID"
          placeholder="Public Account ID"
          v-model="elasticmail.accountId"
        />
      </div>

      <div v-else>
        <FormField label="Host" placeholder="Host" v-model="smtp.host" />

        <FormField label="Porta" placeholder="Porta" v-model="smtp.port" />

        <FormField label="Usuário" placeholder="Usuário" v-model="smtp.user" />

        <FormField label="Senha" placeholder="Senha" v-model="smtp.password" />

        <FormField
          label="E-mail do remetente"
          placeholder="E-mail do remetente"
          v-model="smtp.sender"
        />

        <FormField
          label="Nome do remetente"
          placeholder="Nome do remetente"
          v-model="smtp.senderName"
        />

        <FormCheckbox
          label="Usar conexão segura ssl/tls"
          v-model="smtp.secure"
        />
      </div>

      <a
        :href="
          mailService === 'ELASTIC_MAIL' ? linkAjudaElastic : linkAjudaSMTP
        "
        target="_new"
      >
        {{
          mailService === 'ELASTIC_MAIL'
            ? 'Ajuda com a integração de e-mail via ElasticMail'
            : 'Ajuda com a integração de e-mail SMTP'
        }}
      </a>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import FormCheckbox from '@/components/form/FormCheckbox'

export default {
  components: {
    Panel,
    LineDivider,
    FormSelect,
    FormField,
    FormCheckbox
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      mailService: 'ELASTIC_MAIL',
      mailServiceOptions: [
        { text: 'ElasticMail', value: 'ELASTIC_MAIL' },
        { text: 'SMTP', value: 'SMTP' }
      ],
      elasticmail: {
        apiKey: '',
        accountId: ''
      },
      smtp: {
        host: '',
        port: '',
        user: '',
        password: '',
        sender: '',
        senderName: '',
        secure: true
      },
      linkAjudaElastic:
        'https://atendimento.lifeapps.com.br/kb/article/379683/como-criar-modelos-de-e-mail-templates?ticketId=&q=e-mail',
      linkAjudaSMTP:
        'https://atendimento.lifeapps.com.br/kb/article/380427/envio-de-e-mail-usando-o-smtp?ticketId=&q=e-mail'
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.fornecedor = newVal.fornecedor
          this.areavenda = newVal.areavenda
          if (!oldVal) {
            this.mailService = newVal.mailService
          }
          if (newVal && oldVal && newVal.mailService !== oldVal.mailService) {
            this.mailService = newVal.mailService
          }
          this.smtp = newVal.smtp
          this.elasticmail = newVal.elasticmail
        }
      }
    },
    mailService: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'mailService', dados: newVal })
        }
      }
    },
    elasticmail: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'elasticmail', dados: newVal })
        }
      }
    },
    smtp: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'smtp', dados: newVal })
        }
      }
    }
  }
}
</script>

<style scoped>
a {
  color: #337ab7 !important;
  font-size: 12px !important;
  display: block;
  text-align: left;
  text-decoration: none;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}
</style>
