<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com ERP</h4>
      <LineDivider />

      <div v-if="statusStack">
        <p class="help-block">
          Esses dados são apenas informativos e refletem as pendências de envio
          da integração para o e-commerce.
          <br />
          Esses status se atualizam de tempos em tempos se a integração estiver
          executando normalmente.
        </p>

        <div class="erp">
          <label>ERP Integrado:</label>
          <a href="#" class="badge badge-cinza">
            {{ statusStack.erp }}
          </a>
        </div>

        <div class="erp">
          <label>Último recebimento de status:</label>
          <a
            href="#"
            class="badge badge-cinza"
            :title="formatDate(statusStack.data)"
          >
            {{ formatDateCalendar(statusStack.data) }}
          </a>
        </div>

        <div class="tabela">
          <table>
            <thead>
              <tr>
                <th width="12%">Item integrado</th>
                <th width="14%">Operações pendentes</th>
                <th width="74%">Observações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="i in statusStack.stack" :key="i.entity">
                <td>{{ i.entity }}</td>

                <td>
                  <a href="#" class="badge badge-azul">
                    {{ i.quantity }}
                  </a>
                </td>

                <td>
                  <div v-if="i.depends">
                    A fila de <b> {{ i.entity }}</b> pode demorar um pouco se
                    houverem itens pendentes na fila de
                    <b> {{ i.depends }}</b>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <LineDivider />
      </div>

      <FormField
        label="Código ERP"
        placeholder="Código do ERP"
        v-model="fornecedor.iderp"
      />

      <FormField
        label="Cód. Tabela de Preço Padrão"
        placeholder="Código Região ERP"
        v-model="areavenda.idregiaoerp"
      />

      <label>Token</label>
      <p class="help-block">
        A API do LifeApps Omnichannel permite que aplicações externas se
        comuniquem com a nossa plataforma.
        <br />
        Você é identificado pelo token de integração abaixo. Utilize-o nas
        integrações quando necessário.<br />
        Lembre-se de guardar seu token em segurança.
      </p>

      <FormText v-model="tokenApiView" :noResize="true" :readonly="true" />

      <label>Configurações da integração PWC</label>
      <p class="help-block">
        Caso utilize a integração do PWC, provida pela LifeApps, utilize as
        configurações abaixo no seu cliente de integração (aba de
        configurações):
      </p>

      <ul>
        <li>
          <b>Paje Root:</b>
          <code>{{ helpPaje }}</code>
        </li>
      </ul>

      <a :href="linkAjudaAPI" target="_new" class="ajuda">
        Ajuda com a documentação da API
      </a>

      <a :href="linkAjudaToken" target="_new" class="ajuda">
        Ajuda com o funcionamento do token
      </a>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormText from '@/components/form/FormText'
import { GET_ERP_STACK } from '@/store/actions/integracoes'
import moment from 'moment'
import * as _ from 'lodash'

moment.updateLocale('pt', {
  calendar: {
    lastDay: '[Ontem às] LT',
    sameDay: '[Hoje às] LT',
    nextDay: '[Amanhã às] LT',
    lastWeek: '[Último] dddd [às] LT',
    nextWeek: 'dddd [às] LT',
    sameElse: 'L'
  }
})

export default {
  components: {
    Panel,
    LineDivider,
    FormField,
    FormText
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      fornecedor: {
        iderp: null
      },
      areavenda: {
        idareavendafornecedor: null,
        idregiaoerp: null
      },
      statusStack: null,
      linkAjudaAPI:
        'https://atendimento.lifeapps.com.br/kb/article/379709/quero-integrar-meu-erp-com-a-plataforma-onde-encontro-a-document?ticketId=&q=quero%20integrar%20meu%20erp%20com%20a%20plataforma%20onde%20encontro',
      linkAjudaToken:
        'https://atendimento.lifeapps.com.br/kb/article/380421/duvidas-referente-ao-token-de-integracao?ticketId=&q=duvidas%20referente%20ao%20token%20de%20integra%C3%A7%C3%A3o'
    }
  },
  created() {
    this.getErpStackStatus()
  },
  computed: {
    tokenApiView() {
      if (this.fornecedor.tokenApi)
        return 'Bearer ' + atob(this.fornecedor.tokenApi)
      return ''
    },
    helpPaje() {
      return _.get(this.fornecedor, 'configsServer.help.configPajeRoot', '--')
    }
  },
  methods: {
    getErpStackStatus() {
      this.$store.dispatch(GET_ERP_STACK).then(statusStack => {
        this.statusStack = statusStack
      })
    },
    formatDateCalendar(newDate) {
      return moment(newDate).calendar()
    },
    formatDate(newDate) {
      return moment(newDate).format('DD/MM/YYYY HH:mm')
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.fornecedor = newVal.fornecedor
          this.areavenda = newVal.areavenda
        }
      }
    },
    fornecedor: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'fornecedor', dados: newVal })
        }
      }
    },
    areavenda: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'areavenda', dados: newVal })
        }
      }
    }
  }
}
</script>

<style scoped>
label {
  display: block;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  color: #6a6c6f;
}

a {
  text-decoration: none;
}

ul {
  width: 100%;
  text-align: left;
  padding-left: 40px;
  margin-bottom: 20px;
}

li {
  color: #6a6c6f;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

b {
  font-weight: 700;
  padding-right: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  color: #6a6c6f;
}

th,
td {
  padding: 8px;
  text-align: left;
}

td {
  border-top: 1px solid #ddd;
}

.ajuda {
  color: #337ab7 !important;
  font-size: 12px !important;
  display: block;
  text-align: left;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #737373;
  text-align: left;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
}

.badge-cinza {
  background-color: #777;
}

.badge-azul {
  background-color: #3498db;
}

.erp {
  text-align: left;
  margin-bottom: 10px;
}

.tabela {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
