<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Integração com serviço de SMS</h4>
      <LineDivider />

      <FormSelect
        label="Provedor"
        placeholder="Provedor"
        v-model="sms.providor"
        :options="smsServiceOptions"
      />

      <FormField
        label="Usuário"
        placeholder="Nome do usuário de integração"
        v-model="sms.user"
      />

      <FormField
        label="Senha"
        placeholder="Senha do usuário de integração"
        v-model="sms.password"
      />
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'

export default {
  components: {
    Panel,
    LineDivider,
    FormSelect,
    FormField
  },
  props: {
    dados: {
      type: Object,
      default: function() {
        return {}
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      sms: {
        user: '',
        password: '',
        providor: 'zenvia'
      },
      smsServiceOptions: [{ text: 'Zenvia', value: 'zenvia' }]
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.sms = newVal.sms
        }
      }
    },
    sms: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('dataToParent', { evento: 'sms', dados: newVal })
        }
      }
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}
</style>
